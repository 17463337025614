<template>
  <div class="b-page">
    <div class="b-page__content">
      <h1 class="main-page">ЗАКОНЫ ПРИКАЗЫ РАСПОРЯЖЕНИЯ </h1>
      <div class="container block-margin-bottom">
        <div class="mini_banners">
          <div class="container block-margin">
            <div v-for="(item,index) in laws" :key="index" class="code_div">
              <a :href="item.link" class="code_link" ><span class="code_link_span">{{item.name}}</span></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Code",
  setup() {
    const laws = [
      {
        name: "Постановление Правительства Российской Федерации от 15 сентября 2020г №1434 \"Об утверждении Правил проведения технического осмотра транспортных средств, а также о внесении изменений в некоторые акты Правительства Российской Федерации",
        link: "/files/Постановление_Правительства_ТО_№1434.pdf"
      },
      {
        name: "Федеральный закон Российской Федерации от 1 июля 2011 года N 170-ФЗ г. Москва \"О техническом осмотре транспортных средств и о внесении изменений в отдельные законодательные акты Российской Федерации\"",
        link: "/files/FZ_170.doc"
      },
      {
        name: "Приказ Министерства экономического развития Российской Федерации (Минэкономразвития России) от 14 октября 2011 г. N 573 г. Москва \"Об утверждении формы типового договора о проведении технического осмотра\"",
        link: "/files/minek_573.doc"
      },
      {
        name: "Приказ Министерства экономического развития Российской Федерации (Минэкономразвития России) от 28 ноября 2011 г. N 697 г. Москва \"Об утверждении Правил аккредитации операторов технического осмотра\"",
        link: "/files/minek_697.doc"
      },
      {
        name: "Приказ Министерства промышленности Российской Федерации (Минпромторг России) от 6 декабря 2011 г. N 1677 г. Москва \"Об утверждении основных технических характеристик средств технического диагностирования и их перечн",
        link: "/files/minpromtorg_1677.doc"
      },
      {
        name: "РОССИЙСКАЯ ФЕДЕРАЦИЯ ФЕДЕРАЛЬНЫЙ ЗАКОН О ВНЕСЕНИИ ИЗМЕНЕНИЙ В ОТДЕЛЬНЫЕ ЗАКОНОДАТЕЛЬНЫЕ АКТЫ РОССИЙСКОЙ ФЕДЕРАЦИИ",
        link: "/files/FZ_1303.doc"
      },
      {
        name: "Постановление Правительства Российской Федерации от 13 ноября 2013 г",
        link: "/files/postanovlenie13102013.doc"
      },
      {
        name: "Приказ Федеральной службы по финансовым рынкам (ФСФР России) от 6 мая 2013 г. N 13-32/пз-н г. Москва",
        link: "/files/PrikazN 13-32.doc"
      }
    ]
    return {
      laws
    }
  }
}
</script>

<style scoped>
.code_div{
  padding: 10px 0;
  border-bottom: #5e5e5e 1px solid;
}
.code_link{
  font-size: 120%;
  text-transform: uppercase;
  text-decoration: none;

}
.code_link:hover{
  color: #4ba82e;
}
</style>
